import * as React from 'react'
import { Collection } from '@byll/hermes'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { getPageSize } from './getPageSize'
import { Button } from 'components/Form/components/Button'
import { Spinner } from 'components/Spinner'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'

interface Props {
  collection:
    | Collection<any, { page?: string; count?: number }, { page?: string }>
    | UnsyncedCollection<any, { page?: string; count?: number }, { page?: string }>
  incrementBy?: number // How many more items should be loaded on button click?
  className?: string
  singularText?: string // Displayed if all resources are loaded and there is exactly one resource
  pluralText?: string // Displayed if all resources are loaded and there are 0 or more than 1 resources. Supports placeholder ${count}
}

@observer
export class LoadMoreButton extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private loadMore = () => {
    if (!this.props.collection.metadata || !this.props.collection.resources) {
      return
    }
    const currentPageSize =
      getPageSize(this.props.collection.metadata.page) ||
      this.props.collection.resources.length
    const incrementBy = this.props.incrementBy || 50
    this.props.collection.query.page = `0,${currentPageSize + incrementBy}`
  }

  render() {
    const metadata = this.props.collection.metadata
    const resources = this.props.collection.resources
    if (!metadata || !resources) {
      return null
    }
    const loading = metadata.page !== this.props.collection.query.page
    // If total resource count is not available (metadata.count), then use second best measure to find out if there
    // are more resources to be loaded and assume there are more resources if requested page length is fulfilled.
    // This may lead to one unnecessary .loadMore call if resources.length === total count && resources.length is a
    // multiple of props.incrementBy. For some collection types this is acceptable because the alternative would be
    // increased database load as resource count would have to be retrieved with every call.
    const hasMore =
      typeof metadata.count === 'number'
        ? resources.length < metadata.count
        : resources.length === getPageSize(metadata.page)

    return (
      <div
        className={this.props.className || 'mt4 mb4 tc'}
        style={{ height: 38, lineHeight: '38px' }}
      >
        {loading && (
          <div className='relative'>
            <Spinner />
          </div>
        )}
        {!loading && hasMore && (
          <Button color='secondary' outline onClick={this.loadMore}>
            <i className='fas fa-caret-down' /> Weitere Ergebnisse
          </Button>
        )}
        {!loading && !hasMore && (
          <span className='text-gray-500 text-md'>
            {resources.length === 1
              ? this.props.singularText || `Sie sehen alle Ergebnisse`
              : this.props.pluralText?.replace(`\${count}`, String(resources.length)) ||
                `Sie sehen alle ${resources.length} Ergebnisse`}
          </span>
        )}
      </div>
    )
  }
}
