import { Resource } from '@byll/hermes'
import { useEffect, useState } from 'react'

export function useResource<T extends { id: string }>(path: string): Resource<T> {
  const [res] = useState(() => new Resource<T>(path))
  useEffect(() => {
    return res.init()
  }, [res])
  return res
}
