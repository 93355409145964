import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../../../../../../../../../../components/Form/Model'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { InternalResidenceFormRoomList } from './InternalResidenceFormRoomList'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'
import { AppContext } from 'services/connection/models/AppContext'
import { hermes } from '@byll/hermes'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { dayjs } from 'helpers/dayjs'

const validFoods = {
  Vollverpflegung: 'Vollverpflegung',
  Selbstverpflegung: 'Selbstverpflegung',
}

interface Props {
  model: Model<IInternalResidenceFormModel>
  setStep: (step: 'beds') => void
  onClose: () => void
  color: 'secondary' | 'warning'
  checkInPermission: 0 | 1 | 2
}

@observer
export class InternalResidenceFormStep2 extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () =>
          `${this.props.model.values.compoundId}.${this.props.model.values.buildingId}`,
        () => (this.error = null),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onSubmit = () => {
    if (!this.props.model.values.buildingId) {
      this.error = 'Bitte wählen Sie ein Gelände und ein Gebäude aus.'
      return
    }

    if (!this.props.model.values.roomId) {
      this.error = 'Bitte wählen Sie einen Raum aus.'
      return
    }

    if (
      this.context.permissions.host_lfgb &&
      this.props.model.values.type === 'internal-residence'
    ) {
      // Use Vollverpflegung / Selbstverpflegung from building as default for this booking
      const building = hermes.getFromStore<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings/${this.props.model.values.buildingId}`,
      )
      runInAction(
        () => (this.props.model.values.food = validFoods[building?.food || ''] || ''),
      )
    }

    if (
      this.context.instance.id === '1' &&
      this.props.model.values.type === 'internal-residence' &&
      (this.props.model.values.buildingId === '341' ||
        this.props.model.values.buildingId === '319')
    ) {
      // Preselect ROB rast buildings
      this.props.model.values.hasRast = true
    }

    this.props.setStep('beds')
  }

  render() {
    const onlyStamm =
      (this.props.model.values.type === 'internal-residence' &&
        this.props.checkInPermission === 1) ||
      (this.props.model.values.type === 'internal-reservation' &&
        this.context.permissions.resident_reservationCheckInOut === 1)
    const beginAt = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    ).toISOString()
    return (
      <>
        <div className='relative grid grid-cols-2 gap-4 mt-4'>
          <InputCompound
            model={this.props.model}
            name='compoundId'
            label='Gelände'
            onlyStamm={onlyStamm}
            at={beginAt}
          />
          <InputBuilding
            model={this.props.model}
            name='buildingId'
            label='Gebäude'
            compoundId={this.props.model.values.compoundId}
            onlyStamm={onlyStamm}
            at={beginAt}
          />
          {this.error && (
            <Message color='danger' className='col-span-2'>
              {this.error}
            </Message>
          )}
          {!this.error && !this.props.model.values.buildingId && (
            <Message color='primary' className='col-span-2'>
              Sobald Sie ein Gelände und ein Gebäude ausgewählt haben, erscheint hier eine
              Liste der dazugehörigen Räume.
            </Message>
          )}
        </div>
        {this.props.model.values.buildingId && (
          <InternalResidenceFormRoomList
            key={this.props.model.values.buildingId}
            buildingId={this.props.model.values.buildingId}
            model={this.props.model}
          />
        )}
        <div className='mt-4 flex text-right py-4 px-6 -mx-6 sticky bottom-0 bg-white -mb-4 border-t border-gray-200'>
          <Button
            className='ml-auto'
            color='secondary'
            outline
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </Button>
          <Button color={this.props.color} className='ml-2' onClick={this.onSubmit}>
            Weiter
          </Button>
        </div>
      </>
    )
  }
}
