import { IResident } from '../interfaces/IResident'

export function getEmptyResident(instanceId: string): IResident {
  return {
    id: '',
    instanceId,
    familyId: '',
    imageId: null,
    createdAt: new Date().toISOString(),
    deletedAt: null,
    firstName: '',
    lastName: '',
    aliasName: '',
    oldNames: [],
    dateOfBirth: null,
    oldDatesOfBirth: [],
    mId: null,
    bvNr: null,
    tId: null,
    nationalityId: null,
    countryOfBirthId: null,
    category: null,
    nativeLanguage: '',
    languageIds: '',
    cityOfBirth: '',
    religion: '',
    sex: null,
    title: '',
    familyRelation: '',
    roleInFamily: '',
    kaId: '',
    wimNr: null,
    pickUpLocation: '',
    legalAufenthaltsstatus: '',
    legalAufenthaltsstatusSeit: null,
    legalAufenthaltsstatusComment: '',
    maritalStatus: '',
    comment: '',
    hasParentData: false,
    parentData: '',
    parentDataDate: null,
    guardianName: '',
    guardianAgency: '',
    guardianPhone: '',
    guardianFax: '',
    guardianEmail: '',
    rguDate: null,
    rguResult: '',
    gvhCheckDate: null,
    gvhCheckResult: '',
    alterseinschaetzungDate: null,
    alterseinschaetzungResult: '',
    alterseinschaetzungNewDateOfBirth: null,
    alterseinschaetzungIsLawsuit: false,
    alterseinschaetzungLawsuitResult: null,
    inobhutnahmeState: null,
    inobhutnahmeBeginDate: null,
    inobhutnahmeEndDate: null,
    p41State: null,
    p41Comment: '',
    p41Date: null,
    p41ProposalDate: null,
    p41ApprovalState: null,
    p41ApprovalDate: null,
    p41ApprovalUserName: '',
    hasAsylumRequest: false,
    asylumRequestDate: null,
    asylumApplicationSubmissionDate: null,
    hasClothingAllowance: false,
    clothingAllowanceDate: null,
    clothingAllowanceAmount: null,
    clearingReportDate: null,
    clearingReport: '',
    federalTransferCharacteristics: '',
    creationUserId: null,
    kindeswohlgefaehrdungCheckbox: false,
    kindeswohlgefaehrdungText: '',
    gesundheitszustandCheckbox: false,
    verwandschaftszusammenfuehrungCheckbox: false,
    verwandschaftszusammenfuehrungText: '',
    gemeinsameInobhutnahmeCheckbox: false,
    allgemeinerAnmerkungstext: '',
    awumZuweisungsdatum: null,
    awumAsylbLGLeistungsanspruch: '',
    awumAsylbLGLeistungsanspruchSeit: null,
    awumOrdnungsmerkmal: '',
    ethnicity: '',
    leaBamfId: null,
    leaGnaCheckbox: false,
    leaGnaText: '',
    leaSchuleCheckbox: false,
    leaSchuleText: '',
    leaSpecialNeedsCheckbox: false,
    leaSpecialNeedsText: '',
    leaRamadan: false,
    leaBarcodeScanAlarmText: '',
    migVisId: null,
    leaInactive: false,
    leaInactiveReason: '',
    kommFuBelegungshinweise: '',
    lfgUnterbringungsgrund: null,
    lfgAufenthaltsstatus: null,
    lfgAufenthaltsstatusValidTill: null,
    lfgSchutzbeduerftig: '',
    lfgDetailSchutzbedarf: '',
    lfgSozialarbeiter: null,
    lfgInitialerCheckIn: null,
    vySoftId: '',
    email: '',
    phone: '',
    vulnerableGruppen: '',
    contactBevorzugterKanal: '',
    briefkasten: null,
    abschiebeschutzberechtigt: false,
    isChild: false,
    isAdult: false,
    kriegsfluchtUkr: false,
    personalCharacteristicsIsSchwangerschaft: false,
    personalCharacteristicsIsEinzelzimmer: false,
    personalCharacteristicsIsRollstuhl: false,
    personalCharacteristicsIsBarrierefreiheit: false,
    jugendamtId: null,
    azrNr: null,
    hasSchonkost: false,
    foodMustBeBroughtToRoom: false,
    lunchPackage: '',
  }
}
